import React from "react";
import LinkComponent from "../Link";

interface Props {
  links: LinkType[];
}

export default function LinksGroup({ links }: Props) {
  const lastIndex = links.length - 1;
  const isMoreThanOneLink = links.length > 1;

  return (
    <span>
      {links.map((link, index) => {
        const isNotLastIndex = index !== lastIndex;
        const isCommaVisible = isMoreThanOneLink && isNotLastIndex;
        return (
          <React.Fragment key={link._uid}>
            <LinkComponent {...link} className="underline ml-4" />
            {isCommaVisible && ","}
          </React.Fragment>
        );
      })}
    </span>
  );
}
