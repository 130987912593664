import React, { useContext, useEffect, useMemo } from "react";
import { graphql, PageProps } from "gatsby";

import { useLanguageContext, LANGUAGE_ACTIONS } from "@/context";
import { parseLayout, parseProject } from "@/base/helpers/parsers";
import Layout from "../layouts/Layout";
import InvestmentsSection from "../sections/InvestmentsSection";
import ProjectDetailsSection from "../sections/ProjectDetailsSection";
import RelatedNewsSection from "../elements/RelatedNewsSection";

/** Data that comes from page query. */
interface PageData {
  layout: StoryblokStory;
  projectStory: StoryblokStory;
  sectionInvestmentsSettings: {
    edges: {
      node: { name: string; value: string };
    }[];
  };
}

/** Data that comes from gatsby-node.js file. */
type PageContextExtended = PageContext<PageTemplate> & {
  slug: string;
  projectUuid: string;
  news: NewsPostType[];
  exampleProjects: ParsedStoryblokStory<PortfolioProjectType>[];
};

export default function Project({
  pageContext,
  location,
  data,
}: PageProps<PageData, PageContextExtended>) {
  const {
    lang,
    languages,
    alternates,
    news = [],
    exampleProjects,
  } = pageContext;

  const { header, footer } = parseLayout(data.layout);
  const project = parseProject(data.projectStory);
  const exampleNews = news.slice(0, 3);

  const { dispatch } = useContext(useLanguageContext);

  useEffect(() => {
    dispatch({
      type: LANGUAGE_ACTIONS.setLanguage,
      payload: lang,
    });
    dispatch({
      type: LANGUAGE_ACTIONS.setLanguages,
      payload: languages,
    });
  }, []);

  const seo = {
    title: `${project.name} - Vertex Portfolio`,
    description: project.description,
    imageUrl: project.image.image,
  };

  const investmentsSettings = useMemo(() => {
    return Object.fromEntries(
      data.sectionInvestmentsSettings.edges.map((datasource) => [
        datasource.node.name,
        datasource.node.value,
      ])
    );
  }, [data.sectionInvestmentsSettings]);

  return (
    <Layout
      header={header && header[0]}
      footer={footer && footer[0]}
      simpleSeo={seo}
      siteName={"story.name"}
      pathname={location.pathname}
      hash={location.hash}
      alternates={alternates}
    >
      <ProjectDetailsSection project={project} />
      {exampleNews.length > 0 && (
        <RelatedNewsSection
          allNewsHref={`/news/author/${project.slug}`}
          relatedItems={exampleNews}
          showVideos={false}
          isLinkingFromDifferentThanNews
        />
      )}
      <InvestmentsSection
        {...investmentsSettings}
        companies={exampleProjects}
      />
    </Layout>
  );
}

export const projectQuery = graphql`
  query ProjectQuery($langRegex: String!, $projectUuid: String!) {
    layout: storyblokEntry(
      field_component: { eq: "common_layout" }
      full_slug: { regex: $langRegex }
    ) {
      content
    }
    projectStory: storyblokEntry(uuid: { eq: $projectUuid }) {
      uuid
      slug
      content
    }
    sectionInvestmentsSettings: allStoryblokDatasourceEntry(
      filter: {
        data_source: { eq: "section-investments-settings" }
        data_source_dimension: { ne: null }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          name
          value
        }
      }
    }
  }
`;
